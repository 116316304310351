import React, { useState, useMemo } from "react";
import Title from "../../components/Title";
import * as XLSX from "xlsx";
import "./style.scss";
import {
  CommandBar,
  ContextualMenuItemType,
  Dropdown,
  FontIcon,
  ICommandBarItemProps,
  IDropdownOption,
  IDropdownStyles,
} from "@fluentui/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useBoolean } from "@fluentui/react-hooks";
import Notification, {
  INotification,
} from "../../components/InlineNotification";
import { useDropzone } from "react-dropzone";

//const numberOfValues = 95
const numberOfValues = 173;
const errorPlantilla =
  "El formato de la plantilla es incorrecto. Por favor, vuelva a descargarla.";
const defaultKeys = [
  "Código de cadena",
  "Semana",
  "Año",
  "Nº Locales comparables",
  "Nº Locales comparables que reportan",
  "Ventas comparables",
  "Ventas comparables (ap)",
  "Tickets comparables",
  "Tickets comparables (ap)",
  "Aperturas",
  "Cierres",
  "Nº Locales",
  "Nº Locales que reportan",
  "Ventas totales",
  "Ventas totales (año pasado)",
  "Tickets totales",
  "Tickets totales (año pasado)",
  "Ventas delivery",
  "Ventas delivery (ap)",
  "Tickets delivery",
  "Tickets delivery (ap)",
  "Locales con delivery",
  "Locales con delivery (ap)",
  "Ventas take away",
  "Ventas take away (ap)",
  "Tickets take away",
  "Tickets take away (ap)",
  "Ventas local",
  "Ventas local (ap)",
  "Tickets local",
  "Tickets local (ap)",
  "Ventas calle",
  "Ventas calle (ap)",
  "Tickets calle",
  "Tickets calle (ap)",
  "Ventas c.comercial",
  "Ventas c.comercial (ap)",
  "Tickets c.comercial",
  "Tickets c.comercial (ap)",
  "Ventas concesiones",
  "Ventas concesiones (ap)",
  "Tickets concesiones",
  "Tickets concesiones (ap)",
  "Ventas resto ubicaciones",
  "Ventas resto ubicaciones (ap)",
  "Tickets resto ubicaciones",
  "Tickets resto ubicaciones (ap)",
  "Ventas Madrid",
  "Ventas Madrid (ap)",
  "Tickets Madrid",
  "Tickets Madrid (ap)",
  "Ventas Barcelona",
  "Ventas Barcelona (ap)",
  "Tickets Barcelona",
  "Tickets Barcelona (ap)",
  "Ventas Zona 3",
  "Ventas Zona 3 (ap)",
  "Tickets Zona 3",
  "Tickets Zona 3 (ap)",
  "Ventas Zona 4",
  "Ventas Zona 4 (ap)",
  "Tickets Zona 4",
  "Tickets Zona 4 (ap)",
  "Ventas Zona 5",
  "Ventas Zona 5 (ap)",
  "Tickets Zona 5",
  "Tickets Zona 5 (ap)",
  "Ventas Zona 6",
  "Ventas Zona 6(ap)",
  "Tickets Zona 6",
  "Tickets Zona 6 (ap)",
  "Ventas realizadas en la mañana",
  "Ventas realizadas en la mañana (ap)",
  "Tickets realizadas en la mañana",
  "Tickets realizadas en la mañana (ap)",
  "Ventas realizadas en la tarde",
  "Ventas realizadas en la tarde (ap)",
  "Tickets realizadas en la tarde",
  "Tickets realizadas en la tarde (ap)",
  "Ventas realizadas en comidas de diario",
  "Ventas realizadas en comidas de diario (ap)",
  "Tickets realizadas en comidas de diario",
  "Tickets realizadas en comidas de diario (ap)",
  "Ventas realizadas en cenas de diario",
  "Ventas realizadas en cenas de diario (ap)",
  "Tickets realizadas en cenas de diario",
  "Tickets realizadas en cenas de diario (ap)",
  "Ventas realizadas en comidas de fin de semana",
  "Ventas realizadas en comidas de fin de semana (ap)",
  "Tickets realizadas en comidas de fin de semana",
  "Tickets realizadas en comidas de fin de semana (ap)",
  "Ventas realizadas en cenas de fin de semana",
  "Ventas realizadas en cenas de fin de semana (ap)",
  "Tickets realizadas en cenas de fin de semana",
  "Tickets realizadas en cenas de fin de semana (ap)",
  "Locales self standing durante la semana",
  "Locales self standing durante la semana (ap)",
  "Ventas comparables delivery",
  "Ventas comparables delivery (ap)",
  "Tickets comparables delivery",
  "Tickets comparables delivery (ap)",
  "Ventas comparables take away",
  "Ventas comparables take away (ap)",
  "Tickets comparables take away",
  "Tickets comparables take away (ap)",
  "Ventas comparables local",
  "Ventas comparables local (ap)",
  "Tickets comparables local",
  "Tickets comparables local (ap)",
  "Ventas comparables calle",
  "Ventas comparables calle (ap)",
  "Tickets comparables calle",
  "Tickets comparables calle (ap)",
  "Ventas comparables c.comercial",
  "Ventas comparables c.comercial (ap)",
  "Tickets comparables c.comercial",
  "Tickets comparables c.comercial (ap)",
  "Ventas comparables concesiones",
  "Ventas comparables concesiones (ap)",
  "Tickets comparables concesiones",
  "Tickets comparables concesiones (ap)",
  "Ventas comparables resto ubicaciones",
  "Ventas comparables resto ubicaciones (ap)",
  "Tickets comparables resto ubicaciones",
  "Tickets comparables resto ubicaciones (ap)",
  "Ventas comparables Madrid",
  "Ventas comparables Madrid (ap)",
  "Tickets comparables Madrid",
  "Tickets comparables Madrid (ap)",
  "Ventas comparables Barcelona",
  "Ventas comparables Barcelona (ap)",
  "Tickets comparables Barcelona",
  "Tickets comparables Barcelona (ap)",
  "Ventas comparables zona 3",
  "Ventas comparables zona 3 (ap)",
  "Tickets comparables zona 3",
  "Tickets comparables zona 3 (ap)",
  "Ventas comparables zona 4",
  "Ventas comparables zona 4 (ap)",
  "Tickets comparables zona 4",
  "Tickets comparables zona 4 (ap)",
  "Ventas comparables zona 5",
  "Ventas comparables zona 5 (ap)",
  "Tickets comparables zona 5",
  "Tickets comparables zona 5 (ap)",
  "Ventas comparables zona 6",
  "Ventas comparables zona 6 (ap)",
  "Tickets comparables zona 6",
  "Tickets comparables zona 6 (ap)",
  "Ventas comparables en la mañana",
  "Ventas comparables en la mañana (ap)",
  "Tickets comparables en la mañana",
  "Tickets comparables en la mañana (ap)",
  "Ventas comparables en la tarde",
  "Ventas comparables en la tarde (ap)",
  "Tickets comparables en la tarde",
  "Tickets comparables en la tarde (ap)",
  "Ventas comparables comidas de diario",
  "Ventas comparables comidas de diario (ap)",
  "Tickets comparables comidas de diario",
  "Tickets comparables comidas de diario (ap)",
  "Ventas comparables cenas de diario",
  "Ventas comparables cenas de diario (ap)",
  "Tickets comparables cenas de diario",
  "Tickets comparables cenas de diario (ap)",
  "Ventas comparables comidas fin de semana",
  "Ventas comparables comidas fin de semana (ap)",
  "Tickets comparables comidas fin de semana",
  "Tickets comparables comidas fin de semana (ap)",
  "Ventas comparables cenas fin de semana",
  "Ventas comparables cenas fin de semana (ap)",
  "Tickets comparables cenas fin de semana",
  "Tickets comparables cenas fin de semana (ap)",
];
//const defaultDBKeys = ["cadena", "semana", "anyo", "locales_comp", "localesrep_comp", "ventas_comp", "ventas_comp_ap", "tickets_comp", "tickets_comp_ap", "locales_aperturas", "locales_cierres", "locales", "localesrep", "ventas", "ventas_ap", "tickets", "tickets_ap", "del_ventas", "del_ventas_ap", "del_tickets", "del_tickets_ap", "del_locales", "del_localesrep", "taw_ventas", "taw_ventas_ap", "taw_tickets", "taw_tickets_ap", "local_ventas", "local_ventas_ap", "local_tickets", "local_tickets_ap", "calle_ventas", "calle_ventas_ap", "calle_tickets", "calle_tickets_ap", "cc_ventas", "cc_ventas_ap", "cc_tickets", "cc_tickets_ap", "conces_ventas", "conces_ventas_ap", "conces_tickets", "conces_tickets_ap", "otros_ventas", "otros_ventas_ap", "otros_tickets", "otros_tickets_ap", "z1_ventas", "z1_ventas_ap", "z1_tickets", "z1_tickets_ap", "z2_ventas", "z2_ventas_ap", "z2_tickets", "z2_tickets_ap", "z3_ventas", "z3_ventas_ap", "z3_tickets", "z3_tickets_ap", "z4_ventas", "z4_ventas_ap", "z4_tickets", "z4_tickets_ap", "z5_ventas", "z5_ventas_ap", "z5_tickets", "z5_tickets_ap", "z6_ventas", "z6_ventas_ap", "z6_tickets", "z6_tickets_ap", "t1_ventas", "t1_ventas_ap", "t1_tickets", "t1_tickets_ap", "t2_ventas", "t2_ventas_ap", "t2_tickets", "t2_tickets_ap", "t3_ventas", "t3_ventas_ap", "t3_tickets", "t3_tickets_ap", "t4_ventas", "t4_ventas_ap", "t4_tickets", "t4_tickets_ap", "t5_ventas", "t5_ventas_ap", "t5_tickets", "t5_tickets_ap", "t6_ventas", "t6_ventas_ap", "t6_tickets", "t6_tickets_ap", "ss_locales", "ss_locales_comp"]
//const defaultDBKeys1 = ["cadena", "semana", "anyo", "locales_comp", "localesrep_comp", "ventas_comp", "ventas_comp_ap", "tickets_comp", "tickets_comp_ap", "locales_aperturas", "locales_cierres", "locales", "localesrep", "ventas", "ventas_ap", "tickets", "tickets_ap", "del_ventas", "del_ventas_ap", "del_tickets", "del_tickets_ap", "del_locales", "del_localesrep", "taw_ventas", "taw_ventas_ap", "taw_tickets", "taw_tickets_ap", "local_ventas", "local_ventas_ap", "local_tickets", "local_tickets_ap", "calle_ventas", "calle_ventas_ap", "calle_tickets", "calle_tickets_ap", "cc_ventas", "cc_ventas_ap", "cc_tickets", "cc_tickets_ap", "conces_ventas", "conces_ventas_ap", "conces_tickets", "conces_tickets_ap", "otros_ventas", "otros_ventas_ap", "otros_tickets", "otros_tickets_ap", "z1_ventas", "z1_ventas_ap", "z1_tickets", "z1_tickets_ap", "z2_ventas", "z2_ventas_ap", "z2_tickets", "z2_tickets_ap", "z3_ventas", "z3_ventas_ap", "z3_tickets", "z3_tickets_ap", "z4_ventas", "z4_ventas_ap", "z4_tickets", "z4_tickets_ap", "z5_ventas", "z5_ventas_ap", "z5_tickets", "z5_tickets_ap", "z6_ventas", "z6_ventas_ap", "z6_tickets", "z6_tickets_ap", "t1_ventas", "t1_ventas_ap", "t1_tickets", "t1_tickets_ap", "t2_ventas", "t2_ventas_ap", "t2_tickets", "t2_tickets_ap", "t3_ventas", "t3_ventas_ap", "t3_tickets", "t3_tickets_ap", "t4_ventas", "t4_ventas_ap", "t4_tickets", "t4_tickets_ap", "t5_ventas", "t5_ventas_ap", "t5_tickets", "t5_tickets_ap", "t6_ventas", "t6_ventas_ap", "t6_tickets", "t6_tickets_ap"]

const defaultDBKeys = [
  "cadena",
  "semana",
  "anyo",
  "locales_comp",
  "localesrep_comp",
  "ventas_comp",
  "ventas_comp_ap",
  "tickets_comp",
  "tickets_comp_ap",
  "locales_aperturas",
  "locales_cierres",
  "locales",
  "localesrep",
  "ventas",
  "ventas_ap",
  "tickets",
  "tickets_ap",
  "del_ventas",
  "del_ventas_ap",
  "del_tickets",
  "del_tickets_ap",
  "del_locales",
  "del_locales_ap",
  "taw_ventas",
  "taw_ventas_ap",
  "taw_tickets",
  "taw_tickets_ap",
  "local_ventas",
  "local_ventas_ap",
  "local_tickets",
  "local_tickets_ap",
  "calle_ventas",
  "calle_ventas_ap",
  "calle_tickets",
  "calle_tickets_ap",
  "cc_ventas",
  "cc_ventas_ap",
  "cc_tickets",
  "cc_tickets_ap",
  "conces_ventas",
  "conces_ventas_ap",
  "conces_tickets",
  "conces_tickets_ap",
  "otros_ventas",
  "otros_ventas_ap",
  "otros_tickets",
  "otros_tickets_ap",
  "z1_ventas",
  "z1_ventas_ap",
  "z1_tickets",
  "z1_tickets_ap",
  "z2_ventas",
  "z2_ventas_ap",
  "z2_tickets",
  "z2_tickets_ap",
  "z3_ventas",
  "z3_ventas_ap",
  "z3_tickets",
  "z3_tickets_ap",
  "z4_ventas",
  "z4_ventas_ap",
  "z4_tickets",
  "z4_tickets_ap",
  "z5_ventas",
  "z5_ventas_ap",
  "z5_tickets",
  "z5_tickets_ap",
  "z6_ventas",
  "z6_ventas_ap",
  "z6_tickets",
  "z6_tickets_ap",
  "t1_ventas",
  "t1_ventas_ap",
  "t1_tickets",
  "t1_tickets_ap",
  "t2_ventas",
  "t2_ventas_ap",
  "t2_tickets",
  "t2_tickets_ap",
  "t3_ventas",
  "t3_ventas_ap",
  "t3_tickets",
  "t3_tickets_ap",
  "t4_ventas",
  "t4_ventas_ap",
  "t4_tickets",
  "t4_tickets_ap",
  "t5_ventas",
  "t5_ventas_ap",
  "t5_tickets",
  "t5_tickets_ap",
  "t6_ventas",
  "t6_ventas_ap",
  "t6_tickets",
  "t6_tickets_ap",
  "del_ventas_comp",
  "del_tickets_comp",
  "taw_ventas_comp",
  "taw_tickets_comp",
  "local_ventas_comp",
  "local_tickets_comp",
  "calle_ventas_comp",
  "calle_tickets_comp",
  "cc_ventas_comp",
  "cc_tickets_comp",
  "conces_ventas_comp",
  "conces_tickets_comp",
  "otros_ventas_comp",
  "otros_tickets_comp",
  "z1_ventas_comp",
  "z1_tickets_comp",
  "z2_ventas_comp",
  "z2_tickets_comp",
  "z3_ventas_comp",
  "z3_tickets_comp",
  "z4_ventas_comp",
  "z4_tickets_comp",
  "z5_ventas_comp",
  "z5_tickets_comp",
  "z6_ventas_comp",
  "z6_tickets_comp",
  "t1_ventas_comp",
  "t1_tickets_comp",
  "t2_ventas_comp",
  "t2_tickets_comp",
  "t3_ventas_comp",
  "t3_tickets_comp",
  "t4_ventas_comp",
  "t4_tickets_comp",
  "t5_ventas_comp",
  "t5_tickets_comp",
  "t6_ventas_comp",
  "t6_tickets_comp",
];
const defaultDBKeys1 = [
  "cadena",
  "semana",
  "anyo",
  "locales_comp",
  "localesrep_comp",
  "ventas_comp",
  "ventas_comp_ap",
  "tickets_comp",
  "tickets_comp_ap",
  "locales_aperturas",
  "locales_cierres",
  "locales",
  "localesrep",
  "ventas",
  "ventas_ap",
  "tickets",
  "tickets_ap",
  "del_ventas",
  "del_ventas_ap",
  "del_tickets",
  "del_tickets_ap",
  "del_locales",
  "del_localesrep",
  "taw_ventas",
  "taw_ventas_ap",
  "taw_tickets",
  "taw_tickets_ap",
  "local_ventas",
  "local_ventas_ap",
  "local_tickets",
  "local_tickets_ap",
  "calle_ventas",
  "calle_ventas_ap",
  "calle_tickets",
  "calle_tickets_ap",
  "cc_ventas",
  "cc_ventas_ap",
  "cc_tickets",
  "cc_tickets_ap",
  "conces_ventas",
  "conces_ventas_ap",
  "conces_tickets",
  "conces_tickets_ap",
  "otros_ventas",
  "otros_ventas_ap",
  "otros_tickets",
  "otros_tickets_ap",
  "z1_ventas",
  "z1_ventas_ap",
  "z1_tickets",
  "z1_tickets_ap",
  "z2_ventas",
  "z2_ventas_ap",
  "z2_tickets",
  "z2_tickets_ap",
  "z3_ventas",
  "z3_ventas_ap",
  "z3_tickets",
  "z3_tickets_ap",
  "z4_ventas",
  "z4_ventas_ap",
  "z4_tickets",
  "z4_tickets_ap",
  "z5_ventas",
  "z5_ventas_ap",
  "z5_tickets",
  "z5_tickets_ap",
  "z6_ventas",
  "z6_ventas_ap",
  "z6_tickets",
  "z6_tickets_ap",
  "t1_ventas",
  "t1_ventas_ap",
  "t1_tickets",
  "t1_tickets_ap",
  "t2_ventas",
  "t2_ventas_ap",
  "t2_tickets",
  "t2_tickets_ap",
  "t3_ventas",
  "t3_ventas_ap",
  "t3_tickets",
  "t3_tickets_ap",
  "t4_ventas",
  "t4_ventas_ap",
  "t4_tickets",
  "t4_tickets_ap",
  "t5_ventas",
  "t5_ventas_ap",
  "t5_tickets",
  "t5_tickets_ap",
  "t6_ventas",
  "t6_ventas_ap",
  "t6_tickets",
  "t6_tickets_ap",
];

function cleanVarName(name: string) {
  if (!name || typeof name !== "string") return "";
  name = name.replaceAll("ñ", "n");
  return name
    .replace(/( |:|º|\.|\(|\))/g, "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase(); // nosonar
}

const CargaDatos = () => {
  const [table, setTable] = useState<any>();
  const [allItems, setAllItems] = useState<any>([]);
  const [index, setIndex] = useState(0);
  const [
    showNotification,
    { toggle: toggleNotification, setFalse: closeNotification },
  ] = useBoolean(false);
  const [notification, setNotification] = useState<{
    text: string;
    type: INotification;
  }>({ text: "", type: "default" });

  const getError = (data: any) => {
    let error = "";
    if (
      !(
        Object.entries(data).length === numberOfValues ||
        Object.entries(data).length === numberOfValues + 2
      )
    )
      error =
        "El número de campos no coincide con el de la plantilla. Por favor, vuelva a descargarla";
    if (data["semana"] === undefined) error = errorPlantilla;
    return error;
  };

  const handleDropFile = (file: File[]) => {
    closeNotification();

    const isCSV = file[0]?.name.slice(-4) === ".csv";

    let reader = new FileReader();
    reader.readAsArrayBuffer(file[0]);

    reader.onloadend = (e) => {
      try {
        if (e.target === null || e.target.result === null)
          throw new Error("Se ha producido un error cargando la plantilla.");
        //var data = undefined;
        //if(typeof e?.target?.result === "object") data = new Uint8Array(e.target.result);

        var workbook = XLSX.read(e.target.result, { type: "array" });
        var relevantdata: any = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        );

        let error = "";
        let cTable = [];

        if (
          isCSV &&
          !(
            JSON.stringify(Object.values(relevantdata[3])) ===
              JSON.stringify(defaultDBKeys) ||
            JSON.stringify(Object.values(relevantdata[3])) ===
              JSON.stringify(defaultDBKeys1)
          )
        )
          throw new Error(errorPlantilla);

        if (!isCSV && relevantdata.length < 5) {
          error = errorPlantilla;
        } else {
          if (isCSV && Object.values(relevantdata[3]).length < numberOfValues)
            error = errorPlantilla;
          //const plantillas = ["Código de 5 digitos insertado por Ameba ", "Código de 6 dígitos facilitado por Ameba", "Código de 6 digitos ifacilitado por Ameba ", "Código de cadena de 6 dígitos facilitado por Ameba"]

          let keys: string[];
          let plantilla = 0;
          if (isCSV) {
            plantilla = 4;
            //}else{
            //	keys = Object.values(relevantdata[3])
            //	if(typeof keys[2] === "number" && plantillas.includes(`${Object.values(relevantdata[2])[0]}`)) {
            //		plantilla = 1
            //	}
          }
          keys = defaultKeys; // Para admitir varios tipos de plantilla, forzamos siempre las claves por defecto

          //if (plantilla===0 && JSON.stringify(keys) !== JSON.stringify(defaultKeys)) throw new Error(errorPlantilla)

          let cAllItems: any = [];
          for (let row = 4 - plantilla; row < relevantdata.length; row++) {
            let obj: any = {};
            let values: any[] = Object.values(relevantdata[row]);
            let cAllItem: any = [];

            if (keys.length > values.length)
              throw new Error(
                "Por favor, revise el contenido del fichero, parece que algún campo está incompleto o vacio. Recuerde que los campos que no aplican deben ser 0."
              );

            let cadenas =
              qCadena && qCadena.data ? qCadena.data.getCadena : undefined;
            let cCadena: { CargaIVA: number } | undefined = undefined;
            keys.forEach((k: string, i) => {
              if (!!k)
                obj[cleanVarName(k)] =
                  i === 0 ? values[i] : parseInt(values[i]);
              cAllItem.push({
                key: i,
                name: k.replace("(ap)", "(año pasado)"),
                value: i === 0 ? values[i] : parseInt(values[i]),
              });
              if (i === 0) {
                cCadena = cadenas.filter(
                  (cadena: { Nombre: String }) =>
                    cadena.Nombre === `cad_${values[i]}`
                )[0];
              }
              if (i === 2) {
                if (parseInt(values[i]) < 2019)
                  throw new Error(
                    `Por favor, revise el contenido del fichero. No se ha podido detectar un año válido (${parseInt(
                      values[i]
                    )}).`
                  );
                cAllItem.push({
                  key: -1,
                  name: "Datos con IVA",
                  value:
                    cCadena && cCadena.CargaIVA
                      ? "Incluidos"
                      : "No incluidos (se aplica factor)",
                });
              }
            });

            cAllItems.push(cAllItem);
            error = getError(obj);
            cTable.push(obj);
          }
          setAllItems(cAllItems);
        }

        if (cTable.length === 0) error = errorPlantilla;
        if (error) throw new Error(error);
        setTable(cTable);
      } catch (err: any) {
        let error = err.message;
        setNotification({ text: error, type: "error" });
        toggleNotification();
      }
    };
  };

  let qCadena = useQuery(
    gql`
      query {
        getCadena {
          UUID
          Nombre
          CargaIVA
        }
      }
    `
  );

  const addQuery = gql`
    mutation (
      $cadena: String
      $semana: Int
      $ano: Int
      $nlocalescomparables: Int
      $nlocalescomparablesquereportan: Int
      $ventascomparables: Int
      $ventascomparablesap: Int
      $ticketscomparables: Int
      $ticketscomparablesap: Int
      $aperturas: Int
      $cierres: Int
      $nlocales: Int
      $nlocalesquereportan: Int
      $ventastotales: Int
      $ventastotalesanopasado: Int
      $ticketstotales: Int
      $ticketstotalesanopasado: Int
      $ventasdelivery: Int
      $ventasdeliveryap: Int
      $ticketsdelivery: Int
      $ticketsdeliveryap: Int
      $localescondelivery: Int
      $localescondeliveryap: Int
      $ventastakeaway: Int
      $ventastakeawayap: Int
      $ticketstakeaway: Int
      $ticketstakeawayap: Int
      $ventaslocal: Int
      $ventaslocalap: Int
      $ticketslocal: Int
      $ticketslocalap: Int
      $ventascalle: Int
      $ventascalleap: Int
      $ticketscalle: Int
      $ticketscalleap: Int
      $ventasccomercial: Int
      $ventasccomercialap: Int
      $ticketsccomercial: Int
      $ticketsccomercialap: Int
      $ventasconcesiones: Int
      $ventasconcesionesap: Int
      $ticketsconcesiones: Int
      $ticketsconcesionesap: Int
      $ventasrestoubicaciones: Int
      $ventasrestoubicacionesap: Int
      $ticketsrestoubicaciones: Int
      $ticketsrestoubicacionesap: Int
      $ventasmadrid: Int
      $ventasmadridap: Int
      $ticketsmadrid: Int
      $ticketsmadridap: Int
      $ventasbarcelona: Int
      $ventasbarcelonaap: Int
      $ticketsbarcelona: Int
      $ticketsbarcelonaap: Int
      $ventaszona3: Int
      $ventaszona3ap: Int
      $ticketszona3: Int
      $ticketszona3ap: Int
      $ventaszona4: Int
      $ventaszona4ap: Int
      $ticketszona4: Int
      $ticketszona4ap: Int
      $ventaszona5: Int
      $ventaszona5ap: Int
      $ticketszona5: Int
      $ticketszona5ap: Int
      $ventaszona6: Int
      $ventaszona6ap: Int
      $ticketszona6: Int
      $ticketszona6ap: Int
      $ventasrealizadasenlamanana: Int
      $ventasrealizadasenlamananaap: Int
      $ticketsrealizadasenlamanana: Int
      $ticketsrealizadasenlamananaap: Int
      $ventasrealizadasenlatarde: Int
      $ventasrealizadasenlatardeap: Int
      $ticketsrealizadasenlatarde: Int
      $ticketsrealizadasenlatardeap: Int
      $ventasrealizadasencomidasdediario: Int
      $ventasrealizadasencomidasdediarioap: Int
      $ticketsrealizadasencomidasdediario: Int
      $ticketsrealizadasencomidasdediarioap: Int
      $ventasrealizadasencenasdediario: Int
      $ventasrealizadasencenasdediarioap: Int
      $ticketsrealizadasencenasdediario: Int
      $ticketsrealizadasencenasdediarioap: Int
      $ventasrealizadasencomidasdefindesemana: Int
      $ventasrealizadasencomidasdefindesemanaap: Int
      $ticketsrealizadasencomidasdefindesemana: Int
      $ticketsrealizadasencomidasdefindesemanaap: Int
      $ventasrealizadasencenasdefindesemana: Int
      $ventasrealizadasencenasdefindesemanaap: Int
      $ticketsrealizadasencenasdefindesemana: Int
      $ticketsrealizadasencenasdefindesemanaap: Int
      $localesselfstandingdurantelasemana: Int
      $localesselfstandingdurantelasemanaap: Int
      $ventascomparablesdelivery: Int
      $ventascomparablesdeliveryap: Int
      $ticketscomparablesdelivery: Int
      $ticketscomparablesdeliveryap: Int
      $ventascomparablestakeaway: Int
      $ventascomparablestakeawayap: Int
      $ticketscomparablestakeaway: Int
      $ticketscomparablestakeawayap: Int
      $ventascomparableslocal: Int
      $ventascomparableslocalap: Int
      $ticketscomparableslocal: Int
      $ticketscomparableslocalap: Int
      $ventascomparablescalle: Int
      $ventascomparablescalleap: Int
      $ticketscomparablescalle: Int
      $ticketscomparablescalleap: Int
      $ventascomparablesccomercial: Int
      $ventascomparablesccomercialap: Int
      $ticketscomparablesccomercial: Int
      $ticketscomparablesccomercialap: Int
      $ventascomparablesconcesiones: Int
      $ventascomparablesconcesionesap: Int
      $ticketscomparablesconcesiones: Int
      $ticketscomparablesconcesionesap: Int
      $ventascomparablesrestoubicaciones: Int
      $ventascomparablesrestoubicacionesap: Int
      $ticketscomparablesrestoubicaciones: Int
      $ticketscomparablesrestoubicacionesap: Int
      $ventascomparablesmadrid: Int
      $ventascomparablesmadridap: Int
      $ticketscomparablesmadrid: Int
      $ticketscomparablesmadridap: Int
      $ventascomparablesbarcelona: Int
      $ventascomparablesbarcelonaap: Int
      $ticketscomparablesbarcelona: Int
      $ticketscomparablesbarcelonaap: Int
      $ventascomparableszona3: Int
      $ventascomparableszona3ap: Int
      $ticketscomparableszona3: Int
      $ticketscomparableszona3ap: Int
      $ventascomparableszona4: Int
      $ventascomparableszona4ap: Int
      $ticketscomparableszona4: Int
      $ticketscomparableszona4ap: Int
      $ventascomparableszona5: Int
      $ventascomparableszona5ap: Int
      $ticketscomparableszona5: Int
      $ticketscomparableszona5ap: Int
      $ventascomparableszona6: Int
      $ventascomparableszona6ap: Int
      $ticketscomparableszona6: Int
      $ticketscomparableszona6ap: Int
      $ventascomparablesenlamanana: Int
      $ventascomparablesenlamananaap: Int
      $ticketscomparablesenlamanana: Int
      $ticketscomparablesenlamananaap: Int
      $ventascomparablesenlatarde: Int
      $ventascomparablesenlatardeap: Int
      $ticketscomparablesenlatarde: Int
      $ticketscomparablesenlatardeap: Int
      $ventascomparablescomidasdediario: Int
      $ventascomparablescomidasdediarioap: Int
      $ticketscomparablescomidasdediario: Int
      $ticketscomparablescomidasdediarioap: Int
      $ventascomparablescenasdediario: Int
      $ventascomparablescenasdediarioap: Int
      $ticketscomparablescenasdediario: Int
      $ticketscomparablescenasdediarioap: Int
      $ventascomparablescomidasfindesemana: Int
      $ventascomparablescomidasfindesemanaap: Int
      $ticketscomparablescomidasfindesemana: Int
      $ticketscomparablescomidasfindesemanaap: Int
      $ventascomparablescenasfindesemana: Int
      $ventascomparablescenasfindesemanaap: Int
      $ticketscomparablescenasfindesemana: Int
      $ticketscomparablescenasfindesemanaap: Int
      $modelizacion: Int
    ) {
      setVenta(
        input: {
          Usuario: { UUID: "000000000-0000-0000-0000-00000000000" }
          Cadena: { UUID: $cadena }
          Semana: $semana
          Anyo: $ano
          Actual: {
            Ventas: {
              Bruto: {
                Totales: $ventastotales
                Canal: {
                  Delivery: $ventasdelivery
                  TakeAway: $ventastakeaway
                  Local: $ventaslocal
                }
                Ubicacion: {
                  Calle: $ventascalle
                  CC: $ventasccomercial
                  Concesiones: $ventasconcesiones
                  Otros: $ventasrestoubicaciones
                }
                Region: {
                  Z1: $ventasmadrid
                  Z2: $ventasbarcelona
                  Z3: $ventaszona3
                  Z4: $ventaszona4
                  Z5: $ventaszona5
                  Z6: $ventaszona6
                }
                Momento: {
                  T1: $ventasrealizadasenlamanana
                  T2: $ventasrealizadasenlatarde
                  T3: $ventasrealizadasencomidasdediario
                  T4: $ventasrealizadasencenasdediario
                  T5: $ventasrealizadasencomidasdefindesemana
                  T6: $ventasrealizadasencenasdefindesemana
                }
              }
              Comparable: {
                Totales: $ventascomparables
                Canal: {
                  Delivery: $ventascomparablesdelivery
                  TakeAway: $ventascomparablestakeaway
                  Local: $ventascomparableslocal
                }
                Ubicacion: {
                  Calle: $ventascomparablescalle
                  CC: $ventascomparablesccomercial
                  Concesiones: $ventascomparablesconcesiones
                  Otros: $ventascomparablesrestoubicaciones
                }
                Region: {
                  Z1: $ventascomparablesmadrid
                  Z2: $ventascomparablesbarcelona
                  Z3: $ventascomparableszona3
                  Z4: $ventascomparableszona4
                  Z5: $ventascomparableszona5
                  Z6: $ventascomparableszona6
                }
                Momento: {
                  T1: $ventascomparablesenlamanana
                  T2: $ventascomparablesenlatarde
                  T3: $ventascomparablescomidasdediario
                  T4: $ventascomparablescenasdediario
                  T5: $ventascomparablescomidasfindesemana
                  T6: $ventascomparablescenasfindesemana
                }
              }
            }
            Tickets: {
              Bruto: {
                Totales: $ticketstotales
                Canal: {
                  Delivery: $ticketsdelivery
                  TakeAway: $ticketstakeaway
                  Local: $ticketslocal
                }
                Ubicacion: {
                  Calle: $ticketscalle
                  CC: $ticketsccomercial
                  Concesiones: $ticketsconcesiones
                  Otros: $ticketsrestoubicaciones
                }
                Region: {
                  Z1: $ticketsmadrid
                  Z2: $ticketsbarcelona
                  Z3: $ticketszona3
                  Z4: $ticketszona4
                  Z5: $ticketszona5
                  Z6: $ticketszona6
                }
                Momento: {
                  T1: $ticketsrealizadasenlamanana
                  T2: $ticketsrealizadasenlatarde
                  T3: $ticketsrealizadasencomidasdediario
                  T4: $ticketsrealizadasencenasdediario
                  T5: $ticketsrealizadasencomidasdefindesemana
                  T6: $ticketsrealizadasencenasdefindesemana
                }
              }
              Comparable: {
                Totales: $ticketscomparables
                Canal: {
                  Delivery: $ticketscomparablesdelivery
                  TakeAway: $ticketscomparablestakeaway
                  Local: $ticketscomparableslocal
                }
                Ubicacion: {
                  Calle: $ticketscomparablescalle
                  CC: $ticketscomparablesccomercial
                  Concesiones: $ticketscomparablesconcesiones
                  Otros: $ticketscomparablesrestoubicaciones
                }
                Region: {
                  Z1: $ticketscomparablesmadrid
                  Z2: $ticketscomparablesbarcelona
                  Z3: $ticketscomparableszona3
                  Z4: $ticketscomparableszona4
                  Z5: $ticketscomparableszona5
                  Z6: $ticketscomparableszona6
                }
                Momento: {
                  T1: $ticketscomparablesenlamanana
                  T2: $ticketscomparablesenlatarde
                  T3: $ticketscomparablescomidasdediario
                  T4: $ticketscomparablescenasdediario
                  T5: $ticketscomparablescomidasfindesemana
                  T6: $ticketscomparablescenasfindesemana
                }
              }
            }
            Locales: {
              Aperturas: $aperturas
              Cierres: $cierres
              Bruto: {
                Total: $nlocales
                Reportan: $nlocalesquereportan
                Self: $localesselfstandingdurantelasemana
                Delivery: $localescondelivery
              }
              Comparable: {
                Total: $nlocalescomparables
                Reportan: $nlocalescomparablesquereportan
              }
            }
          }
          Anterior: {
            Ventas: {
              Bruto: {
                Totales: $ventastotalesanopasado
                Canal: {
                  Delivery: $ventasdeliveryap
                  TakeAway: $ventastakeawayap
                  Local: $ventaslocalap
                }
                Ubicacion: {
                  Calle: $ventascalleap
                  CC: $ventasccomercialap
                  Concesiones: $ventasconcesionesap
                  Otros: $ventasrestoubicacionesap
                }
                Region: {
                  Z1: $ventasmadridap
                  Z2: $ventasbarcelonaap
                  Z3: $ventaszona3ap
                  Z4: $ventaszona4ap
                  Z5: $ventaszona5ap
                  Z6: $ventaszona6ap
                }
                Momento: {
                  T1: $ventasrealizadasenlamananaap
                  T2: $ventasrealizadasenlatardeap
                  T3: $ventasrealizadasencomidasdediarioap
                  T4: $ventasrealizadasencenasdediarioap
                  T5: $ventasrealizadasencomidasdefindesemanaap
                  T6: $ventasrealizadasencenasdefindesemanaap
                }
              }
              Comparable: {
                Totales: $ventascomparablesap
                Canal: {
                  Delivery: $ventascomparablesdeliveryap
                  TakeAway: $ventascomparablestakeawayap
                  Local: $ventascomparableslocalap
                }
                Ubicacion: {
                  Calle: $ventascomparablescalleap
                  CC: $ventascomparablesccomercialap
                  Concesiones: $ventascomparablesconcesionesap
                  Otros: $ventascomparablesrestoubicacionesap
                }
                Region: {
                  Z1: $ventascomparablesmadridap
                  Z2: $ventascomparablesbarcelonaap
                  Z3: $ventascomparableszona3ap
                  Z4: $ventascomparableszona4ap
                  Z5: $ventascomparableszona5ap
                  Z6: $ventascomparableszona6ap
                }
                Momento: {
                  T1: $ventascomparablesenlamananaap
                  T2: $ventascomparablesenlatardeap
                  T3: $ventascomparablescomidasdediarioap
                  T4: $ventascomparablescenasdediarioap
                  T5: $ventascomparablescomidasfindesemanaap
                  T6: $ventascomparablescenasfindesemanaap
                }
              }
            }
            Tickets: {
              Bruto: {
                Totales: $ticketstotalesanopasado
                Canal: {
                  Delivery: $ticketsdeliveryap
                  TakeAway: $ticketstakeawayap
                  Local: $ticketslocalap
                }
                Ubicacion: {
                  Calle: $ticketscalleap
                  CC: $ticketsccomercialap
                  Concesiones: $ticketsconcesionesap
                  Otros: $ticketsrestoubicacionesap
                }
                Region: {
                  Z1: $ticketsmadridap
                  Z2: $ticketsbarcelonaap
                  Z3: $ticketszona3ap
                  Z4: $ticketszona4ap
                  Z5: $ticketszona5ap
                  Z6: $ticketszona6ap
                }
                Momento: {
                  T1: $ticketsrealizadasenlamananaap
                  T2: $ticketsrealizadasenlatardeap
                  T3: $ticketsrealizadasencomidasdediarioap
                  T4: $ticketsrealizadasencenasdediarioap
                  T5: $ticketsrealizadasencomidasdefindesemanaap
                  T6: $ticketsrealizadasencenasdefindesemanaap
                }
              }
              Comparable: {
                Totales: $ticketscomparablesap
                Canal: {
                  Delivery: $ticketscomparablesdeliveryap
                  TakeAway: $ticketscomparablestakeawayap
                  Local: $ticketscomparableslocalap
                }
                Ubicacion: {
                  Calle: $ticketscomparablescalleap
                  CC: $ticketscomparablesccomercialap
                  Concesiones: $ticketscomparablesconcesionesap
                  Otros: $ticketscomparablesrestoubicacionesap
                }
                Region: {
                  Z1: $ticketscomparablesmadridap
                  Z2: $ticketscomparablesbarcelonaap
                  Z3: $ticketscomparableszona3ap
                  Z4: $ticketscomparableszona4ap
                  Z5: $ticketscomparableszona5ap
                  Z6: $ticketscomparableszona6ap
                }
                Momento: {
                  T1: $ticketscomparablesenlamananaap
                  T2: $ticketscomparablesenlatardeap
                  T3: $ticketscomparablescomidasdediarioap
                  T4: $ticketscomparablescenasdediarioap
                  T5: $ticketscomparablescomidasfindesemanaap
                  T6: $ticketscomparablescenasfindesemanaap
                }
              }
            }
            Locales: {
              Aperturas: $aperturas
              Cierres: $cierres
              Bruto: {
                Total: $nlocales
                Reportan: $nlocalesquereportan
                Self: $localesselfstandingdurantelasemanaap
                Delivery: $localescondeliveryap
              }
              Comparable: {
                Total: $nlocalescomparables
                Reportan: $nlocalescomparablesquereportan
              }
            }
          }
          Modelizacion: $modelizacion
        }
      ) {
        UUID
        Error
      }
    }
  `;

  const [addRow] = useMutation(addQuery);

  //const inputRef = useRef(null)

  function sendQuery() {
    let cadenas = qCadena && qCadena.data ? qCadena.data.getCadena : undefined;
    setIndex(0);

    let error = "";
    table.forEach(async (t: any, i: number) => {
      let codigodecadena = isNaN(t.codigodecadena)
        ? t.codigodecadena
        : `cad_${String(t.codigodecadena + "").padStart(6, "0")}`;

      if (codigodecadena.length === 10) {
        let cCadena = cadenas.filter(
          (cadena: { Nombre: String }) => cadena.Nombre === codigodecadena
        )[0];

        if (!cCadena) {
          error =
            "Por favor, revise el contenido del fichero, parece que el código de la cadena o el formato de la plantilla es incorrecto.";
        } else {
          await addRow({
            variables: {
              cadena: cCadena.UUID,
              ...table[i],
              modelizacion: modelizacion,
            },
          }).then(async (row) => {
            if (row.data.setVenta.Error) error = row.data.setVenta.Error;
          });
        }
      } else {
        console.log("Código de cadena mal formado");
        error =
          "Por favor, revise el contenido del fichero, parece que algún campo está incompleto o vacio. Falta información en algún campo crítico.";
      }

      if (error !== "")
        setNotification({
          text: error ?? "Se ha producido un error al almacenar los datos.",
          type: "error",
        });
      if (error === "")
        setNotification({
          text: "Se ha almacenado el registro.",
          type: "success",
        });
      if (i === table.length - 1) toggleNotification();
    });
    setTable(undefined);
    // @ts-ignore
    //if(inputRef.current) inputRef.current.value = "";
  }

  function downloadTemplate() {
    let link = document.createElement("a");
    link.href = `assets/templates/Monitor%20de%20Ventas%20-%20Datos%20de%20ventas.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  let buttonLoad: ICommandBarItemProps[] = [
    {
      key: "loadItems",
      text: "Guardar datos",
      cacheKey: "loadKey",
      iconProps: { iconName: "Upload" },
      onClick: sendQuery,
    },
    {
      key: "prevRow",
      text: "",
      cacheKey: "prevKey",
      disabled: index === 0,
      iconProps: { iconName: "ChevronLeftMed" },
      onClick: () => setIndex(index - 1),
    },
    {
      key: "nextRow",
      text: "",
      cacheKey: "nextKey",
      disabled:
        !allItems || allItems.length === 0 || allItems.length - 1 === index,
      iconProps: { iconName: "ChevronRightMed" },
      onClick: () => setIndex(index + 1),
    },
    {
      key: "numRow",
      text: `Cadena ${index + 1} de ${allItems.length}`,
      cacheKey: "numKey",
      itemType: ContextualMenuItemType.Section,
    },
  ];
  let buttonDownload: ICommandBarItemProps[] = [
    {
      key: "loadItems",
      text: "Descargar plantilla",
      cacheKey: "downloadKey",
      iconProps: { iconName: "Download" },
      onClick: downloadTemplate,
    },
  ];
  let farButtons: ICommandBarItemProps[] = [
    {
      key: "cancel",
      text: "Cancelar",
      iconProps: { iconName: "Cancel" },
      onClick: () => {
        setTable(undefined);
        setIndex(0);
      },
    },
  ];

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept:
      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/x-csv, application/csv, application/x-csv",
    multiple: false,
    onDropAccepted: handleDropFile,
  });

  const style = useMemo(
    () => ({
      flex: 1,
      display: "flex",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#898989",
      backgroundColor: "#dfdddd",
      borderStyle: "dashed",
      color: "#474747",
      outline: "none",
      transition: "border .24s ease-in-out",
      margin: "2px 4px",
      ...(isDragActive
        ? {
            color: "#1e58a0",
            borderColor: "#1e58a0",
            backgroundColor: "#d0d4d9",
          }
        : {}),
      ...(isDragAccept
        ? {
            color: "#11814a",
            borderColor: "#11814a",
            backgroundColor: "#d0d9d5",
          }
        : {}),
      ...(isDragReject
        ? {
            color: "#d90e36",
            borderColor: "#d90e36",
            backgroundColor: "#d9d0d0",
          }
        : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const dropdownStyles: Partial<IDropdownStyles> = {
    root: {
      transform: "translateY(-4px)",
      marginLeft: 34,
      zIndex: 1,
      display: "flex",
      gap: 1,
    },
    label: {
      color: "var(--primary-color)",
      fontWeight: 400,
      padding: "7px 0px",
      fontSize: "13px",
      margin: "0px 6px 0px 0px",
    },
    title: {
      borderColor: "transparent",
      transition: "background 0.3s",
    },
    dropdown: {
      selectors: {
        "&:hover .ms-Dropdown-title, &:active .ms-Dropdown-title": {
          borderColor: "transparent",
          background: "#f3f2f1",
        },
        ":focus::after": {
          border: "1px solid transparent",
        },
      },
    },
    callout: {
      minWidth: 130,
    },
  };

  const [modelizacion, setModelizacion] = useState(0);

  const optsModelizacion: IDropdownOption[] = [
    { key: 0, text: "No modelizado" },
    { key: 1, text: "Suma de partes" },
    { key: 2, text: "Total" },
  ];

  const handleModelizacion = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any>,
    index?: number
  ) => {
    setModelizacion(index || 0);
  };

  return (
    <>
      <Title title={"Carga de datos"} />
      {!table && (
        <div className="table-container">
          <div className="table">
            <CommandBar items={buttonDownload} />
            <div style={{ margin: "0 4px" }}>
              <Notification
                text={notification.text}
                type={notification.type}
                visible={showNotification}
                isMultiline={true}
                toggleVisibility={toggleNotification}
                autoHide={false}
              />
            </div>
            <div className="container">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <div className="labelIcon">
                  <FontIcon iconName="CloudUpload" />
                  <p>
                    Arrastra aquí la plantilla cumplimentada o haz clic para
                    seleccionarla
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {table && (
        <div className="table-container">
          <div className="table">
            <CommandBar items={buttonLoad} farItems={farButtons} />
            <Dropdown
              label="Modelización"
              placeholder={"No modelizado"}
              options={optsModelizacion}
              styles={dropdownStyles}
              onChange={handleModelizacion}
            />
            <div className="row header">
              <div className="cell headercell">Campos cargados</div>
              <div className="cell headercell">Valores cargados</div>
            </div>
            {allItems[index].map(
              (item: { key: number; name: string; value: any }, i: number) => {
                return (
                  <div key={item.key} className="row">
                    <div className="cell field">{item.name}</div>
                    <div className="cell value">{item.value}</div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CargaDatos;
