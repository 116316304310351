import { gql, useQuery } from "@apollo/client";
import {
  MessageBar,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
} from "@fluentui/react";
import React, { useContext } from "react";
import AppContext, { IFilters } from "../../../AppContext";
import Title from "../../../components/Title";
import "./style.scss";

export const title = "Cobertura";
export const filters: IFilters = {
  limitedPeriodo: false,
  showMedidas: false,
  hasTicketMedio: false,
  isDesglose: false,
  showEntorno: false,
  showSegmentos: false,
  showMyData: false,
};

const Graph = () => {
  const { semanas } = useContext(AppContext);

  const GET_GRAPH = gql`
    query ($yr1: Int!, $wk1: Int!, $yr2: Int!, $wk2: Int!) {
      graph(
        Graph: "Posicion global"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading || qry.data === undefined;
  let error = !semanas || !!qry.error;
  let emptyData = {
    Eventos: [""],
    Comentarios: [""],
    Grupo: false,
    Cadenas: [""],
    Error: false,
  };
  let data = emptyData;

  if (!!qry?.data) {
    try {
      data = qry?.data?.graph;
      if (!data) throw new Error(errorMsg);
      if (data?.Error) throw new Error(`${data.Error}`);
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }

  const counterShimmer = (
    <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
      <ShimmerElementsGroup
        flexWrap
        width={"100%"}
        shimmerElements={[
          {
            type: ShimmerElementType.line,
            width: 90,
            height: 17,
            verticalAlign: "top",
          },
          {
            type: ShimmerElementType.gap,
            width: "calc(100% - 90px)",
            height: 32,
          },
          {
            type: ShimmerElementType.line,
            width: "100%",
            height: 28,
            verticalAlign: "top",
          },
          { type: ShimmerElementType.gap, width: "100%", height: 32 },
          {
            type: ShimmerElementType.line,
            width: 90,
            height: 17,
            verticalAlign: "top",
          },
          {
            type: ShimmerElementType.gap,
            width: "calc(100% - 90px)",
            height: 32,
          },
          {
            type: ShimmerElementType.line,
            width: "100%",
            height: 28,
            verticalAlign: "top",
          },
        ]}
      />
    </div>
  );
  const cadenasQSR = [
    "McDonald's",
    "Burger King",
    "Popeye's",
    "Telepizza",
    "KFC",
    "Viena Establiments",
    "Five Guys",
    "Pans & Co",
    "Rodilla",
    "Papizza",
    "Carl's Jr",
    "Taco Bell",
    "The Fitzgerald",
    "Papa John’s",
    "Pizza Móvil",
    "Don G",
    "Pomodoro",
    "Pizzería Carlos",
    "Basics",
    "Basics dark",
  ];
  const cadenaCASUAL = [
    "Udon",
    "Ribs",
    "Hamb. Nostra",
    "La Tagliatella",
    "MasQMenos",
    "Volapié",
    "Goiko",
    "Tony Roma's",
    "Tommy Mels",
    "Chelinda",
    "La Mafia",
    "Cantina Mariachi",
    "Muerde la Pasta",
    "Lateral",
    "Lizarran",
    "Café y Tapas",
    "Dehesa de Santa Maria",
    "FrescCo",
    "Singulares Cía del Trópico",
    "Grosso Napoletano",
    "Saona",
    "Ditaly",
    "La Boca Te Lía",
    "Vezzo",
  ];
  const cadenasCAFE = [
    "McCafé",
    "Tim Horton's",
    "Jamaica",
    "Santa Gloria",
    "L'Obrador",
    "Juan Valdez Café",
    "Café de Indias",
    "Café y Té",
    "Panaria",
    "Rodilla Café",
    "Levadura Madre",
    "El Fornet",
    "Pans Café",
    "Dunkin’",
  ];

  return (
    <>
      <Title title={title} />
      {error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>}
      {!error && (
        <div className="global-container">
          {!loading && (
            <>
              <div className="cadenas-container">
                {/*
					{!data.Grupo && data.Cadenas &&
						<div className="notification">Se está mostrando información para la {data.Cadenas[0]?.toLowerCase()}.</div>
					}
					{data.Grupo &&
						<>
							<label>Cadenas del grupo</label>
							<ul>
								{data.Cadenas.map((cadena,i)=>{
									return <li key={i}>{cadena}</li>
								})}
							</ul>
						</>
					}
					*/}
                <>
                  <div>
                    <div className="notification">
                      <label>Segmento QSR</label>
                      <ul>
                        {cadenasQSR.map((cad, i) => (
                          <li key={i}>{cad}</li>
                        ))}
                      </ul>
                      <label>Segmento casual</label>
                      <ul>
                        {cadenaCASUAL.map((cad, i) => (
                          <li key={i}>{cad}</li>
                        ))}
                      </ul>
                      <label>Segmento Café</label>
                      <ul>
                        {cadenasCAFE.map((cad, i) => (
                          <li key={i}>{cad}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              </div>
              {/* <div className="eventos-container">
					<div className="eventos">
						<div className="title">Clima</div>
						<div>
							<ul>
								{console.log(data)}
								{data.Eventos.length===0 &&
									<li key={-1}>No hay eventos en el periodo indicado.</li>
								}
								{data.Eventos.length>0 &&
									<>
									{data.Eventos.map((evento,i)=>{
										return <li key={i}>{evento}</li>
									})}
									</>
								}

							</ul>
						</div>
					</div>
					<div className="comentarios">
						<div className="title">Comentarios</div>
						<div>
							<ul>
								{data.Comentarios.length===0 &&
									<li key={-1}>No hay comentarios en el periodo indicado.</li>
								}
								{data.Comentarios.length>0 &&
									<>
									{data.Comentarios.map((comentario,i)=>{
										return <li key={i}>{comentario}</li>
									})}
									</>
								}
							</ul>
						</div>
					</div>
				</div> */}
            </>
          )}
          {loading && (
            <Shimmer customElementsGroup={counterShimmer} width="400px" />
          )}
        </div>
      )}
    </>
  );
};

export default Graph;

/*
 const cadenasQSR = [
    "Pans & Co",
    "McDonald's",
    "Rodilla",
    "Viena Establiments",
    "The Fitzgerald",
    "Papizza",
    "Telepizza",
    "Five Guys",
    "Taco Bell",
    "Popeye's",
    "Burger King",
    "Carl's Jr",
    "KFC",
  ];

  const cadenaCASUAL = [
    "Ribs",
    "Hamburguesa Nostra",
    "Muerde la Pasta",
    "Volapié",
    "Mas Que Menos",
    "La Tagliatella",
    "Udon",
    "Goiko Grill",
    "Tommy Mel's",
    "Tony Roma's",
    "Chelinda",
    "La Mafia se Sienta a la Mesa",
    "Cantina Mariachi",
    "Lateral",
  ];

  const cadenaCAFETAPAS = [
    "Jamaica",
    "Café de Indias",
    "Santa Gloria",
    "L'Obrador",
    "Juan Valdez Café",
    "Tim Horton's",
    "Don G",
    "Lizarran",
  ];
	*/
